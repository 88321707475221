export default class Parser {
	static parse(type, data) {
		switch (type) {
			// case "Model":
			//   return this.fromJson(Model, data);
			default:
				return data;
		}
	}
	static fromJson(type, data) {
		if (data.constructor === Array) {
			const result = [];
			data.forEach((element) => {
				result.push(new type(element));
			});
			return result;
		} else return new type(data);
	}
}
