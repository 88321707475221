var render = function render(){var _vm=this,_c=_vm._self._c;return _c('footer',{staticClass:"position-fixed w-100",class:{ 'with-background': _vm.withBackground }},[_c('div',{staticClass:"d-flex gap-3 justify-content-between align-items-end footer-content"},[_vm._m(0),_c('div',{staticClass:"footer-content-item text-center persona-container",class:{
        'expired-icon': _vm.isExpired,
      }},[_c('img',{staticClass:"persona img-fluid px-xl-4",attrs:{"src":require(`@/assets/images/footer/${
            _vm.isExpired ? 'expired' : _vm.routeName
          }.svg`),"alt":"persona"}})]),_vm._m(1)]),_c('div',{staticClass:"gradient-base w-100"})])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-content-lg-around align-items-end footer-content-item"},[_c('div',[_c('img',{staticClass:"img-fluid est-icon",attrs:{"src":require("@/assets/images/footer/est.svg"),"alt":"est"}})]),_c('p',{staticClass:"develop-text"},[_vm._v("Develop Sustainable Relationships")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-content-end align-items-end footer-content-item"},[_c('p',[_vm._v(" For more info, reach out to us at "),_c('a',{staticClass:"d-block",attrs:{"href":"https://www.cedted.com/","target":"_blank"}},[_vm._v(" cedted@ntsal.com ")])]),_c('a',{staticClass:"d-block",attrs:{"href":"https://ntsal.com/home","target":"_blank"}},[_c('img',{staticClass:"img-fluid powered-by-ntsal",attrs:{"src":require("@/assets/images/footer/powered-by-ntsal.svg"),"alt":"powered-by-ntsal"}})])])
}]

export { render, staticRenderFns }