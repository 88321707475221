<template>
  <div id="app" class="min-vh-100">
    <error-popup v-if="errorObject.errorPopupIsShow" />
    <loading-popup v-if="isLoading" />
    <router-view />
    <app-footer
      :withBackground="routeName == 'home'"
      :isExpired="routeName == 'expired'"
      v-if="
        routeName == 'home' || routeName == 'about' || routeName == 'expired'
      "
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import errorPopup from "./components/error-popup.vue";
import AppFooter from "./components/layout/app-footer.vue";
import LoadingPopup from "./components/loading-popup.vue";
export default {
  components: { errorPopup, AppFooter, LoadingPopup },
  name: "App",
  computed: {
    ...mapGetters(["errorObject", "isLoading"]),
    routeName() {
      return this.$route.name;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>