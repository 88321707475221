<template>
  <div class="page-spacing choose-lang">
    <app-header class="header" v-if="locales && locales.length > 1" />
    <div v-if="localesCondition" class="languages d-flex">
      <div
        v-for="lang in locales"
        :key="lang"
        class="lang-box gradient-outer-container"
      >
        <div
          class="lang-box-content gradient-inner-container d-flex flex-column justify-content-between"
          :class="`${lang}-dir`"
          v-if="messages"
        >
          <p class="text">
            {{ message("CHOOSE_LANGUAGE", lang) }}
          </p>
          <div class="d-flex justify-content-between">
            <span @click="chooseLanguage(lang)" class="anchor">
              {{ message("HELLO", lang) }}
            </span>
            <span @click="chooseLanguage(lang)">
              <img
                :src="require(`@/assets/icons/arrow.svg`)"
                class="arrow"
                alt="arrow"
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppHeader from "@/components/layout/app-header.vue";
import { mapGetters } from "vuex";

export default {
  name: "ChooseLanguage",
  components: { AppHeader },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["locales", "messages", "survey", "message"]),
    localesCondition() {
      return this.locales?.length > 1 && !this.surveyIsExpired && this.messages;
    },
    surveyCodeFromURL() {
      return this.$route.query.code;
    },
    expiredData() {
      return new Date(this.survey?.endDate).getTime();
    },
    browserLang() {
      return navigator?.languages?.[0].toUpperCase();
    },
    todayDate() {
      return new Date().getTime();
    },
    surveyIsExpired() {
      return this.expiredData < this.todayDate;
    },
  },
  methods: {
    async chooseLanguage(lang) {
      try {
        this.$store.dispatch("setLoadingPopup", true);
        await this.$store.dispatch("chooseLanguage", lang);
        this.$router.push({
          name: "about",
          query: { code: this.surveyCodeFromURL },
        });
        this.$store.dispatch("setLoadingPopup", false);
      } catch (error) {
        this.$store.dispatch("toggleErrorPopup", {
          val: true,
          msg: error,
        });
      }
    },
  },
  async mounted() {
    try {
      this.$store.dispatch("setLoadingPopup", true);

      await this.$store.dispatch("getMessages");
      await this.$store.dispatch("getSurvey", this.surveyCodeFromURL);

      this.$store.dispatch("setLoadingPopup", false);

      if (this.surveyIsExpired) {
        if (this.locales.includes(this.browserLang))
          this.$store.dispatch("chooseLanguage", this.browserLang);
        else this.$store.dispatch("chooseLanguage", this.locales[0]);

        this.$router.push({
          name: "expired",
          query: { code: this.surveyCodeFromURL },
        });
      } else if (this.locales?.length < 2) {
        this.chooseLanguage(this.locales[0]);
      }
    } catch (error) {
      this.$store.dispatch("setLoadingPopup", false);
      this.$store.dispatch("toggleErrorPopup", {
        val: true,
        msg: error,
      });
    }
  },
};
</script>
<style lang="scss" scoped>
.page-spacing {
  .header {
    max-width: 54.375rem;
  }
}
.languages {
  gap: 1.25rem;
  margin-top: 2.5rem;
  flex-wrap: wrap;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  justify-content: center;
}
.lang-box {
  width: 21.25rem;
}

.lang-box-content {
  padding: 1.5625rem 1.875rem;
  min-height: 14.375rem;

  .text {
    font-style: normal;
    font-weight: $font-semibold;
    font-size: 2rem;
    line-height: 2rem;
  }

  .anchor {
    font-weight: $font-medium;
    font-size: 1.5rem;
    line-height: 2rem;
    cursor: pointer;
  }

  &.AR-dir {
    line-height: 2rem;
  }
  .arrow {
    width: 1.5rem;
    cursor: pointer;
  }
}
@media (max-width: $lg-breakpoint) {
  .page-spacing {
    overflow: auto;
  }
}
@media (max-width: $md-breakpoint) {
  .languages {
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
  }

  .page-spacing {
    overflow: auto;
  }
  .lang-box {
    width: 100%;
  }
}

@media (max-width: $lg-breakpoint) and (orientation: landscape) {
  .page-spacing {
    .header {
      max-width: initial;
    }
  }
  .lang-box-content {
    min-height: 11rem;
  }
  .languages {
    padding: 0;
    width: 64%;
    justify-content: flex-start;
  }

  .lang-box {
    width: 100%;
  }
}
</style>