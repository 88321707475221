import axios from "axios";
import Parser from "./parser.js";

export default class NetworkManager {
	static async get(apiName, modelName) {
		return await axios.get(apiName).then((response) => {
			return Parser.parse(modelName, response?.data?.response);
		});
	}

	static async post(apiName, body, modelName) {
		return await axios.post(apiName, body).then((response) => {
			return Parser.parse(modelName, response?.data?.response);
		});
	}

	static async patch(apiName, body, modelName) {
		return await axios.patch(apiName, body).then((response) => {
			return Parser.parse(modelName, response?.data?.response);
		});
	}

	static async delete(apiName, payload, modelName) {
		return await axios.delete(apiName, payload).then((response) => {
			return Parser.parse(modelName, response?.data?.response);
		});
	}

	static async put(
		apiName,
		payload,
		modelName,
		contentType = "application/json; charset=utf-8"
	) {
		return await axios
			.put(apiName, payload, {
				headers: { "Content-Type": contentType }
			})
			.then((response) => {
				return Parser.parse(modelName, response?.data?.response);
			});
	}
}
