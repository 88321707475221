<template>
  <header class="d-flex align-items-center justify-content-between mx-auto">
    <div>
      <img
        src="@/assets/images/header/ced_logo.svg"
        alt="ced"
        class="img-fluid ced"
      />
    </div>
    <div>
      <img :src="clientLogo" alt="client logo" class="img-fluid client-logo" />
      <!-- <img
        src="@/assets/images/header/powerd-by-mobile.svg"
        alt="powered by ntsal"
        class="img-fluid powered-icon"
      /> -->
    </div>
  </header>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Header",
  computed: {
    ...mapGetters(["clientLogo"]),
  },
};
</script>

<style lang="scss" scoped>
header {
  justify-content: center;

  .ced {
    width: 5.8125rem;
    height: 2.5rem;
  }
  .client-logo {
    max-height: 3rem;
    min-width: 5rem;
    width: 9rem;
    max-width: 9rem;
  }

  // .powered-icon {
  //  display: none;
  //}
  @media (max-width: $md-breakpoint) {
    justify-content: space-between;
    .header {
      padding-left: 1rem;
      padding-right: 1rem;
    }
    .client-logo {
      max-height: 2rem;
      max-width: 7rem;
    }
    /*
    .powered-icon {
      display: block;
      width: 4.6875rem;
    }*/
  }

  @media (max-width: $sm-breakpoint) {
    .ced {
      width: 4rem;
    }
    /* .text-icon {
       display: none;
     }


    .powered-icon {
      display: block;
      width: 4rem;
    }*/
  }
}
</style>