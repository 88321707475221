import Vue from 'vue'
import Vuex from 'vuex'
import NetworkManager from "@/network/network-manager";

import {
  GET_MESSAGES, GET_SURVEY, SET_SELECTED_LANGUAGE, SET_IS_CONACTED, RESET_SURVEY, ERROR_POPUP, INPUT_IS_FOCUSED, SAVE_ANSWERD_QUESTION, SET_LOADING
} from "./mutation-types";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    messages: null,
    survey: null,
    locales: null,
    submissionCode: null,
    selectedLang: null,
    isConacted: false,
    errorObject: {
      errorPopupIsShow: false,
      errorMsg: ''
    },
    inputIsFocus: false,
    answerdQuestion: [],
    isLoading: false,
    defaultLocale: null,
  },
  getters: {
    messages: (state) => state.messages,
    survey: (state) => state.survey,
    locales: (state) => state.locales,
    defaultLocale: (state) => state.defaultLocale,
    selectedLang: (state) => state.selectedLang,
    isConacted: (state) => state.isConacted,
    submissionCode: (state) => state.submissionCode,
    errorObject: (state) => state.errorObject,
    isLoading: (state) => state.isLoading,
    inputIsFocus: (state) => state.inputIsFocus,
    answerdQuestion: (state) => state.answerdQuestion,
    answerdQuestionLength: (state) => state.answerdQuestion.length,
    clientLogo: (state) => state.survey?.logoUrl,
    message: (state) => (key, lang = '') => {
      let messageWithActualLang = state.messages?.[key]?.[lang ? lang : state.selectedLang]?.message;
      let messageWithDefaultLang = state.messages?.[key]?.[state.defaultLocale]?.message;
      return messageWithActualLang ? messageWithActualLang : messageWithDefaultLang
    }
  },
  mutations: {
    [GET_MESSAGES](state, payload) {
      state.messages = payload;
    },
    [GET_SURVEY](state, payload) {
      if (payload) {
        state.survey = payload.survey;
        state.locales = payload.survey.locales.split(',');
        state.submissionCode = payload.submissionCode;
        state.defaultLocale = payload.survey.defaultLocale;
      }
    },
    [SET_SELECTED_LANGUAGE](state, payload) {
      state.selectedLang = payload
    },
    [SET_IS_CONACTED](state, payload) {
      state.isConacted = payload
    },
    [RESET_SURVEY](state) {
      state.survey = null;
      state.answerdQuestion = []
    },
    [ERROR_POPUP](state, payload) {

      state.errorObject.errorPopupIsShow = payload.val;
      state.errorObject.errorMsg = payload.msg ?? state.messages?.['SERVER_IS_UNREACHABLE'];
    },
    [INPUT_IS_FOCUSED](state, payload) {
      state.inputIsFocus = payload;
    },
    [SAVE_ANSWERD_QUESTION](state, payload) {
      let checkAnswerIndex = state.answerdQuestion.findIndex(item => item.questionCode == payload.questionCode);
      if (checkAnswerIndex == -1) {
        state.answerdQuestion.push(payload);
      } else {
        state.answerdQuestion[checkAnswerIndex] = payload;
      }
    },
    [SET_LOADING](state, payload) {
      state.isLoading = payload
    }
  },
  actions: {
    async getMessages({ commit }) {
      const response = await NetworkManager.get(
        `/messages/front-end?module=SURVEY-ENGINE`
      );
      commit(GET_MESSAGES, response);

    },
    async getSurvey({ commit }, surveyCode) {
      const response = await NetworkManager.get(
        `/surveys/${surveyCode}/init`
      );
      commit(GET_SURVEY, response);
    },
    async chooseLanguage(context, payload) {
      await NetworkManager.post('/surveys/display-lang', {
        displayLang: payload,
        submissionCode: context.state.submissionCode
      });
      context.commit(SET_SELECTED_LANGUAGE, payload)
    },
    setIsConactedValue({ commit }, payload) {
      commit(SET_IS_CONACTED, payload);
    },
    async submitAnswer({ commit }, payload) {
      commit(SAVE_ANSWERD_QUESTION, payload)

      let textQuestionNotEmpty = (payload.questionIsText && (payload.answer.trim().length || payload.negativeAnswer.trim().length))

      if (!payload.questionIsText || textQuestionNotEmpty) {
        let response = await NetworkManager.post('/questions/answer', payload);
        if (response) {
          payload.isAnswered = true;
        }
      } else {
        payload.isAnswered = true;
      }
      commit(SAVE_ANSWERD_QUESTION, payload)
    },
    async contactInfo(_, payload) {
      await NetworkManager.post('/surveys/contact-info', payload);
    },
    resetSurvey({ commit }) {
      commit(RESET_SURVEY);
    },
    toggleErrorPopup({ commit }, payload) {
      commit(ERROR_POPUP, payload)
    },
    setLoadingPopup({ commit }, payload) {
      commit(SET_LOADING, payload)
    },
    setInputFocus({ commit }, payload) {
      commit(INPUT_IS_FOCUSED, payload)
    },
  },
  modules: {
  }
})
