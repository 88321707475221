<template>
  <div
    class="loading vh-100 vw-100 d-flex justify-content-center align-items-center position-fixed"
  >
    <img
      src="@/assets/spinner.gif"
      width="130"
      alt="loading"
      class="img-fluid"
    />
  </div>
</template>

<script>
export default {
  name: "Loading",
};
</script>

<style lang="scss" scoped>
.loading {
  z-index: 20;
  background: $color-dark;
}
</style>