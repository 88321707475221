export const GET_MESSAGES = "GET_MESSAGES";
export const GET_SURVEY = "GET_SURVEY";
export const SET_SELECTED_LANGUAGE = "SET_SELECTED_LANGUAGE";
export const SET_IS_CONACTED = "SET_IS_CONACTED";
export const RESET_SURVEY = "RESET_SURVEY";
export const ERROR_POPUP = "ERROR_POPUP";
export const SET_LOADING = "SET_LOADING";
export const INPUT_IS_FOCUSED = "INPUT_IS_FOCUSED";
export const SAVE_ANSWERD_QUESTION = "SAVE_ANSWERD_QUESTION";


