import Vue from 'vue'
import VueRouter from 'vue-router'
import ChooseLanguage from '../views/choose-language.vue'

Vue.use(VueRouter)
// http://localhost:8080/?code=demo
const routes = [
  {
    path: '/',
    name: 'home',
    component: ChooseLanguage
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/survey-information.vue')
  },
  {
    path: '/survey',
    name: 'survey',
    component: () => import(/* webpackChunkName: "about" */ '../views/survey.vue')
  },
  {
    path: '/thanks',
    name: 'thanks',
    component: () => import(/* webpackChunkName: "about" */ '../views/thanks-page.vue')
  }
  ,
  {
    path: '/conacted',
    name: 'conacted',
    component: () => import(/* webpackChunkName: "about" */ '../views/contact-form.vue')
  },
  {
    path: '/expired',
    name: 'expired',
    component: () => import(/* webpackChunkName: "about" */ '../views/expired-page.vue')
  },
  { path: "*",     component: () => import(/* webpackChunkName: "about" */ '../views/not-found.vue')}
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
