<template>
  <div class="position-fixed error-popup px-4">
    <div class="position-absolute overlay" @click.self="close()"></div>

    <div class="error-content" :class="{ 'direction-ar': isArabic }">
      <div class="d-flex gap-5 align-items-center justify-content-between mb-2">
        <img
          class="error-icon"
          src="@/assets/icons/error.svg"
          :alt="errorObject.errorMsg"
        />
        <img
          @click="close()"
          src="@/assets/icons/close.svg"
          width="10"
          alt="close"
          class="close-icon"
        />
      </div>
      <h1 class="">
        <span>{{ errorObject.errorMsg }}</span>
      </h1>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "errorPopup",
  computed: {
    ...mapGetters(["errorObject", "selectedLang"]),
    isArabic() {
      return this.selectedLang
        ? this.selectedLang == "AR"
        : navigator?.languages?.[0]?.toLowerCase().includes("ar");
    },
  },
  methods: {
    close() {
      this.$store.dispatch("toggleErrorPopup", {
        val: false,
        msg: "",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.error-popup {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 5;

  .overlay {
    background: #1e1e1e;
    opacity: 0.7;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
  }

  .error-content {
    width: 43.75rem;
    max-width: 95%;
    border-radius: 1.25rem;
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    background: $color-dark;
    border: 1px solid $color-gray-400;
    padding: 2rem;

    .error-icon {
      width: 1.5625rem;
    }

    .close-icon {
      width: 1rem;
    }
  }

  h1 {
    font-weight: $font-semibold;
    font-size: 2rem;
    line-height: 2rem;
  }

  img {
    cursor: pointer;
  }
}
</style>