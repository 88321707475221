import { APIs_URL } from "../constants";
import axios from "axios";

axios.defaults.baseURL =
	process.env.NODE_ENV === "production"
		? APIs_URL.PRODUCTION
		: APIs_URL.STAGING;

axios.interceptors.request.use(async function (config) {
	// todo => remove with production
//	config.headers.common["APP"] = "CED"
	return config;
});

axios.interceptors.response.use(
	function (response) {
		// Any status code that lie within the range of 2xx cause this function to trigger
		// Do something with response data
		return response;
	},
	function (error) {
		throw (error.response && error.response.data && error.response.data.message)

	}
);
