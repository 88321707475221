<template>
  <footer
    class="position-fixed w-100"
    :class="{ 'with-background': withBackground }"
  >
    <div
      class="d-flex gap-3 justify-content-between align-items-end footer-content"
    >
      <div
        class="d-flex justify-content-lg-around align-items-end footer-content-item"
      >
        <div>
          <img
            src="@/assets/images/footer/est.svg"
            alt="est"
            class="img-fluid est-icon"
          />
        </div>
        <p class="develop-text">Develop Sustainable Relationships</p>
      </div>
      <div
        class="footer-content-item text-center persona-container"
        :class="{
          'expired-icon': isExpired,
        }"
      >
        <img
          :src="
            require(`@/assets/images/footer/${
              isExpired ? 'expired' : routeName
            }.svg`)
          "
          alt="persona"
          class="persona img-fluid px-xl-4"
        />
      </div>
      <div
        class="d-flex justify-content-end align-items-end footer-content-item"
      >
        <p>
          For more info, reach out to us at
          <a class="d-block" href="https://www.cedted.com/" target="_blank">
            cedted@ntsal.com
          </a>
        </p>
        <a class="d-block" href="https://ntsal.com/home" target="_blank">
          <img
            src="@/assets/images/footer/powered-by-ntsal.svg"
            alt="powered-by-ntsal"
            class="img-fluid powered-by-ntsal"
          />
        </a>
      </div>
    </div>

    <div class="gradient-base w-100"></div>
  </footer>
</template>

<script>
export default {
  name: "AppFooter",
  props: {
    withBackground: {
      type: Boolean,
      default: false,
    },
    bigPersona: {
      type: Boolean,
      default: false,
    },
    isExpired: {
      type: Boolean,
    },
  },
  computed:{
    routeName(){
      return this.$route.name
    }
  }
};
</script>

<style lang="scss" scoped>
footer {
  bottom: 0;
  left: 0;

  .gradient-base {
    background-image: $main-gradient;
    height: 2.4375rem;
  }

  p {
    font-style: normal;
    font-weight: $font-medium;
    font-size: 1rem;
    line-height: 1.3125rem;
    color: $color-gray-100;
  }

  .est-icon {
    width: 6.25rem;
  }

  .persona-container {
    width: 45.875rem;
    margin-bottom: -3rem;
    z-index: 3;
    max-width: 100%;
  }

  .develop-text {
    max-width: 8.75rem;
  }

  .footer-content {
    margin-bottom: 2.25rem;
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }

  .powered-by-ntsal {
    width: 13.4375rem;
  }

  .footer-content-item {
    flex: 1;
    gap: 27%;
  }

  .expired-icon {
    width: 14rem;
    margin-bottom: -4rem;
  }

  @media (max-width: $lg-breakpoint) {
    .footer-content {
      margin-bottom: 1rem;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }

    .persona-container {
      margin-bottom: -1.4rem;
    }
    .footer-content-item {
      gap: 15%;
    }
  }
  @media (max-width: $md-breakpoint) {
    &.with-background {
      background: linear-gradient(
        0deg,
        $color-dark 90.73%,
        rgba(33, 33, 33, 0) 99.05%
      );
      padding-top: 2rem;
      //  height: initial;
    }
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    .powered-by-ntsal,
    p,
    .est-icon {
      display: none;
    }

    .footer-content {
      margin-bottom: initial;
      padding-left: 4.0625rem;
      padding-right: 4.0625rem;
    }

    .persona-container {
      margin-bottom: -0.4rem;
      width: 15.625rem;

      &.big-persona {
        width: 138vw;
        max-width: initial;
        position: absolute;
        bottom: 1.4rem;
        left: 50%;
        transform: translateX(-50%);
      }

      &.expired-icon {
        margin-bottom: -1.3rem;
        img {
          width: 9.6875rem;
        }
      }
    }

    .gradient-base {
      height: 1.5625rem;
    }
    .footer-content-item {
      flex: initial;
    }
  }
  @media (max-height: 730px) and (width: 540px) {
    .persona-container {
      &.big-persona {
        width: 90vw;
      }
    }
  }
  @media (max-width: $xl-breakpoint) and (max-height: 830px) and (orientation: landscape) {
    .persona-container img {
      width: 85%;
    }
  }

  @media (max-width: $lg-breakpoint) and (orientation: landscape) {
    &.with-background {
      background: unset;
      // height: fit-content;
      padding-top: 0;
    }

    .powered-by-ntsal,
    p,
    .est-icon {
      display: none;
    }

    .footer-content-item {
      position: fixed;
      right: 0;
    }

    .persona-container {
      width: 30%;
      max-width: 100%;
      margin-bottom: -1.4rem;

      &.expired-icon {
        margin-bottom: -2rem;

        img {
          width: 60%;
        }
      }
    }

    .persona-container.big-persona {
      width: 15.625rem;
      left: initial;
      right: 0;
      transform: initial;
    }

    .gradient-base {
      height: 1.5rem;
    }
  }

  @media (max-width: $md-breakpoint) and (orientation: landscape) {
    .persona-container {
      margin-bottom: -0.4rem;

      /*  &.expired-icon {
        margin-bottom: -1.3rem;

        img {
          width: 75%;
        }
    } */
    }
  }
}
</style>

